import React, { useState, useEffect } from 'react';
import classes from "./Slideshow.module.css";

const Slideshow = ({ slides, label, link, transition = 2 }) => {
    const [slideIndex, setSlideIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setSlideIndex((prevIndex) => (prevIndex + 1) % slides.length);
        }, transition * 1000);

        return () => clearInterval(interval);
    }, [slides, label, link, transition]);

    return (
        <div>
            <div className={classes.slides}>
                {slides.map((slide, index) => (
                    <div
                        key={index}
                        className={`${classes.mySlides} ${index === slideIndex ? classes.activeSlide : ''} ${classes.mySlides} ${classes.fade}`}
                    >
                        <figure>
                            <a href={link}><img src={slide.url} alt={`Slide ${slide.id}`} /></a><br />
                            <figcaption><a href={link}><i className="bi bi-box-arrow-up-right"></i> {label}</a></figcaption>
                        </figure>
                    </div>
                ))}
            </div>
            <div className={classes.dots}>
                {slides.map((_, index) => (
                    <span
                        key={index}
                        className={`${classes.dot} ${index === slideIndex ? classes.activeDot : ''}`}
                        onClick={() => setSlideIndex(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default Slideshow;
