import classes from "./Footer.module.css";

import React from 'react';

function Footer() {
  return (
    <footer>
      <div className="interface">
        <div className={classes.lineFooter}>
          <div className={`flex ${classes.flex}`}>
            <div className={classes.logo}>
              <img src="img/logo.png" alt="Logo"/>
            </div>
            <div className="btnSocial">
              <a href="/"><button><i className="bi bi-instagram"></i></button></a>
              <a href="/"><button><i className="bi bi-youtube"></i></button></a>
              <a href="/"><button><i className="bi bi-linkedin"></i></button></a>
            </div>
          </div>
        </div>
        <div className={`${classes.lineFooter} ${classes.borda}`}>
          <p><i className="bi bi-envelope-fill"></i> <a href="mailto:xapoca@zumba.com">xapoca@zumba.com</a></p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
