import classes from "./Topo.module.css";

import React from 'react';


function Topo() {
    return (
        <section className={classes.topoSite}>
        <div className="interface">
            <div className={`flex ${classes.parte}`}>
                <div className={classes.txtTopoSite}>
                    <h1 className="titulo">DESVENDANDO A TERRA<br /><span>COM TECNOLOGIA E PRECISÃO.</span></h1>
                    <p>
                        Nossa empresa transforma dados brutos em
                        ferramentas poderosas para o avanço em diversos setores. Combinamos técnicas de coleta online e em campo com soluções para a análise de dados, trazendo informações relevantes para estudos de engenharia, pesquisas de opinião, entre outras aplicações.
                        <br /><br />
                        Garantimos captura e tratamento da informação de forma precisa e segura e criamos mecanismos
                        para facilitar a tomada de decisão estratégica.
                    </p>
                    <div className={`btnContato ${classes.btnContato}`}>
                        <a href='/'><button>Fale com um especialista</button></a>
                    </div>
                </div>
            </div>
        </div>
    </section>
    );
}

export default Topo;
