import classes from "./Header.module.css";

import { useState } from 'react';
// import { Link } from 'react-router-dom';

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  // const toggleMenu = () => {
  //   setMenuOpen(!menuOpen);
  // };

  return (
    <header>
      <div className={`interface ${classes.interface}`}>
        <div className={classes.logo}>
          <a href="/">
            <img src="img/logo.png" alt="Logo" />
          </a>
        </div>

        <nav className={classes.menuDesktop}>
          <ul>
            <li>
              <a href="/">INÍCIO</a>
            </li>
            <li>
              <a href="/">ESPECIALIDADES</a>
            </li>
            <li>
              <a href="/">SOBRE</a>
            </li>
            <li>
              <a href="/">PROJETOS</a>
            </li>
          </ul>
        </nav>

        <div className={classes.btnContato}>
          <a href="/">
            <button>CONTATO</button>
          </a>
        </div>

        <div className={classes.btnAbrirMenu} id="btnMenu">
          <i className="bi bi-list"></i>
        </div>

        <div className={classes.menuMobile} id="menuMobile">
          <div className={classes.btnFechar}>
            <i className="bi bi-x-lg"></i>
          </div>

          <nav>
            <ul>
              <li>
                <a href="/">INÍCIO</a>
              </li>
              <li>
                <a href="/">ESPECIALIDADES</a>
              </li>
              <li>
                <a href="/">SOBRE</a>
              </li>
              <li>
                <a href="/">PROJETOS</a>
              </li>

              <li>
                <a href="/">CONTATO</a>
              </li>
            </ul>
          </nav>
        </div>

        <div className={classes.overlayMenu} id="overlayMenu"></div>

      </div>
    </header>
  );
}

export default Header;
