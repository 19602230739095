import classes from "./Contato.module.css";

import React from 'react';

function Contato() {
    return (
        <section className={classes.formulario}>
            <div className="interface">
                <h2 className="titulo">FALE<span> CONOSCO.</span></h2>

                <form action="">
                    <input type="text" name="" id="" placeholder="Seu nome:" required />
                    <input type="text" name="" id="" placeholder="Seu e-mail:" required />
                    <input type="text" name="" id="" placeholder="Seu celular:" required />
                    <textarea name="" id="" placeholder="Sua mensagem" required></textarea>
                    <div className={classes.btnEnviar}>
                        <input type="submit" value="ENVIAR" />
                    </div>
                </form>
            </div>
        </section>
    );
}

export default Contato;
