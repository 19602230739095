import Slideshow from '../widget/Slideshow';

import classes from "./Portfolio.module.css";

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Portfolio() {
    const webCrawlersLabel = "INVESTIDOR LIBERTÁRIO";
    const webCrawlersLink = "https://www.investidorlibertario.terradata.com.br/";
    const webCrawlersSlides = [
        { url: "img/produtos/investidor-libertario-01.png" },
        { url: "img/produtos/investidor-libertario-02.png" },
        { url: "img/produtos/investidor-libertario-03.png" },
        { url: "img/produtos/investidor-libertario-04.png" },
    ];

    const relatorioEleitoralLabel = "RELATÓRIO ELEITORAL";
    const relatorioEleitoralLink = "www.google.com";
    const relatorioEleitoralSlides = [
        { url: "img/produtos/relatorio-eleitoral-01.png" },
        { url: "img/produtos/relatorio-eleitoral-02.png" },
        { url: "img/produtos/relatorio-eleitoral-03.png" },
        { url: "img/produtos/relatorio-eleitoral-04.png" },
        { url: "img/produtos/relatorio-eleitoral-05.png" },
        // { url: "img/produtos/relatorio-eleitoral-06.png" },
        // { url: "img/produtos/relatorio-eleitoral-07.png" },
        // { url: "img/produtos/relatorio-eleitoral-08.png" },
        // { url: "img/produtos/relatorio-eleitoral-09.png" }
    ];

    return (
        <section className={classes.portfolio}>
            <div className="interface">
                <h2 className="titulo">NOSSO <span>PORTFÓLIO.</span></h2>
                <div className={`flex ${classes.flex}`}>
                    <Container>
                        <Row>
                            <Col sm>
                                <Slideshow slides={relatorioEleitoralSlides} label={relatorioEleitoralLabel} link={relatorioEleitoralLink} />
                            </Col>

                            <Col sm>
                                <Slideshow slides={webCrawlersSlides} label={webCrawlersLabel} link={webCrawlersLink} transition='2.5' />
                            </Col>                            
                        </Row>
                    </Container>
                </div>
            </div>
        </section>
    );
}

export default Portfolio;
