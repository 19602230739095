import classes from "./Sobre.module.css";

import React from 'react';


function Sobre() {
    return (
        <section className={classes.sobre}>
            <div className="interface">
                <div className={`flex ${classes.flex}`}>
                    <div className={classes.imgSobre}>
                        <img src="img/globe.gif" alt="Globe" />
                    </div>
                    <div className={classes.txtSobre}>
                        <h2 className='titulo'>MUITO PRAZER, <span>SOMOS A TERRATADA.</span></h2>
                        <p>
                            Somos especialistas em transformar dados em conhecimento prático e estratégico para o seu negócio. Combinamos tecnologia de ponta e métodos avançados de análise para extrair insights precisos e relevantes que impulsionam decisões e otimizam operações. Nossa missão é fazer com que dados complexos se traduzam em soluções claras e objetivas, ajudando empresas de diferentes setores a antecipar tendências, aumentar sua eficiência e explorar novas oportunidades de mercado. Conte conosco para transformar informação em resultados reais para o seu sucesso.
                        </p>
                        <div className="btnSocial">
                            <a href="/"><button><i className="bi bi-instagram"></i></button></a>
                            <a href="/"><button><i className="bi bi-youtube"></i></button></a>
                            <a href="/"><button><i className="bi bi-linkedin"></i></button></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Sobre;
