import React from 'react';

import Topo from '../components/section/Topo';
import Especialidades from '../components/section/Especialidades';
import Sobre from '../components/section/Sobre';
import Portfolio from '../components/section/Portfolio';
import Contato from '../components/section/Contato';

function Home() {
    return (
        <main>
            <Topo />
            <Especialidades />
            <Sobre />
            <Portfolio />
            <Contato />
        </main>
    );
}

export default Home;