import classes from "./Especialidades.module.css";

import React from 'react';

function Especialidades() {
    return (
        <section className={classes.especialidades}>
        <div className="interface">
            <h2 className="titulo">NOSSAS <span>SOLUÇÕES.</span></h2>
            <div className={`flex ${classes.flex}`}>
                <div className={classes.especialidadesBox}>
                    <i className="bi bi-code-square"></i>
                    <h3>Web Crawlers<br /><br /></h3>
                    <p>
                        Ferramentas customizadas que extraem e organizam informações relevantes de fontes online com agilidade e
                        precisão.
                        <br /><br />
                        O resultado são dados estruturados que podem ser utilizados com diversos propósitos, a depender da natureza da informação.

                        É possível criar soluções
                        para monitoramento de preços e ofertas em e-commerce; para análises de mercado financeiro e
                        ações;
                        para a extração de informações de clientes e contatos para prospecção de vendas, etc.
                        <br /><br />
                        <a href='/'>Saiba mais</a>
                    </p>
                </div>

                <div className={classes.especialidadesBox}>
                    <i className="bi bi-globe2"></i>
                    <h3>CaptureWise<br /><br /></h3>
                    <p>
                        Aplicativo móvel customizável para a coleta de dados em campo, que permite a captura de informação
                        com eficiência e precisão.
                        <br /><br />
                        Obtém dados textuais, imagem, áudio, vídeo e dados de sensores como GPS, acelerômetros e bússolas - enriquecendo o contexto das informações coletadas e permitindo análises avançadas.
                        <br /><br />
                        Opera tanto online quanto offline e permite a sincronização de dados com servidor remoto.
                        <br /><br />
                        <a href='/'>Saiba mais</a>
                    </p>
                </div>

                <div className={classes.especialidadesBox}>
                    <i className="bi bi-map-fill"></i>
                    <h3>Análise e Visualização de Dados</h3>
                    <p>
                        Ferramentas customizadas que permitem transformar dados brutos em insights estratégicos e visuais claros através de gráficos e mapas, dando suporte à tomada de decisão.
                        <br /><br />
                        Possibilitam análises estatísticas e espaciais avançadas, fornecendo métricas e previsões que revelam padrões e relações nos dados. São ferramentas essenciais para identificar tendências, otimizar estratégias e responder rapidamente a mudanças no mercado.
                        <br /><br />
                        <a href='/'>Saiba mais</a>
                    </p>
                </div>
            </div>
        </div>
    </section>
    );
}

export default Especialidades;
